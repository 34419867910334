

import { BW_OPT_1, BW_OPT_2 } from "@/components/TicketCard/constants";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onUpdated,
  PropType,
  ref, toRef,
  toRefs,
  unref,
} from "vue";
import { useStore } from "vuex";
import SvgIcon from "@/components/SvgIcon";
import { ITicket } from "@/types";
import UserAvatar from "@/components/UserAvatar";
import { useFancybox } from "@/composables/useFancybox"
import TicketService from "@/services/ticket_service"
import HiddenButtonComponent from "./HiddenButtonComponent.vue"
import TicketCommentsComponent from "./TicketCommentsComponent.vue";
import {useAppUtils} from '@/composables/useAppUtils'
import {useI18n} from "vue-i18n"
import { autorefreshBlockAdd, autorefreshBlockRemove, autorefreshBlockExists } from "@/composables/useFetchState"
import ApiService from "@/services/api_service"

export default defineComponent({
  name: "ticket-card",
  components: {
    SvgIcon,
    UserAvatar,
    HiddenButtonComponent,
    TicketCommentsComponent
  },
  emits: [
    'mouseleave:lastNote',
    'mouseenter:lastNote',
    'ticketMenu',
  ],
  props: {
    ticket: { type: Object as PropType<ITicket>, required: true },
    isExpanded: { type: Boolean },
    isInProgress: { type: Boolean },
    isAdded: {type: Boolean},
    users: { type: Object as PropType<any> },
    options: Object as PropType<any>,
    mode: { type: String, default: 'normal' }
  },

  setup(props, { emit }) {
    const store = useStore();
    const {t} = useI18n();

    const { ticket, isExpanded } = toRefs(props);

    let tData: any = unref(ticket);

    const options = toRef(props, 'options');

    // const title = computed(() => {
    //   return (options.value.show_ticket_id == '1' ? ticket.value.id + ' - ' : '') + ticket.value.title
    // })
    const title = computed(() => {
      return (options.value.show_ticket_id == '1' ? '<span class="ticket-title-id">#' + (ticket.value.id_html || ticket.value.id) + '</span> - ' : '') + ticket.value.title
    })


    const {
      isSuccessServerResponse,
      showResponseSuccessMessage,
      getMinDarkColor
    } = useAppUtils()

    const minDarkColor = getMinDarkColor()

    const isDark = computed(() => ticket.value.tmpColor ? parseInt(ticket.value.tmpColor!, 10) >= minDarkColor : parseInt(ticket.value.color!, 10) >= minDarkColor ? true : false);

    const isReadonly = computed(()=> ticket.value.isEdit != "1");
    const isShowDetailsArrow = computed(()=>!!ticket.value.description);

    const isShowTags = computed(() => options.value.tags_align != "hidden" && (tData.tags || tData.new_tag || tData.upd_tag));

    const isNew = computed(()=>ticket.value.new_tag);
    const isUpdate = computed(()=>ticket.value.upd_tag);
    // const ticketHref = '/ticket/' + tData.id;

    const isShowCover = computed(() => options.value.show_cover != 0);
    const { openFancybox } = useFancybox()

    //const coverWithLink = computed(()=> (tData.cover && tData.cover && tData.cover.type == "file" && !tData.cover.isPdf));

    // const isShowOwner = computed(() => options.value.bw_user_field == BW_OPT_1.BW_OPT_SHOW_OWNER);
    // const isShowUsers = computed(() => (options.value.bw_user_field == BW_OPT_1.BW_OPT_SHOW_USERS_AVATARS || options.value.bw_user_field == BW_OPT_1.BW_OPT_SHOW_USERS_NAMES));

    const isShowOwner = computed(() => options.value.bw_user_field_type === 'author')
    const isShowUsers = computed(() => options.value.bw_user_field_type === 'team')
    const isShowDeadline = computed(() => options.value.bw_date_field == BW_OPT_2.BW_OPT_SHOW_DEADLINE_DATE && ticket.value.deadline_date);
    const isShowCreateDate = computed(() => options.value.bw_date_field == BW_OPT_2.BW_OPT_SHOW_CREATION_DATE && ticket.value.insert_time);
    const isShowTodos = computed(() => options.value.bv_show_todo != 0 && ticket.value.todos);
    const isShowNotes = computed(() => options.value.bv_show_notes != 0 && ticket.value.noteCnt);

    const isNoteActive = computed(()=>ticket.value.noteCntActive)
    const isLastNoteActive = false; //(context!.attrs!.lastOpen || 0) < (tData.lastNoteTime || 0);

    const noteCnt = computed(()=>ticket.value.noteCnt ? ticket.value.noteCnt < 99 ? ticket.value.noteCnt : '...' : '');

    let lastNote = { id: tData.lastNoteId };

    const customFieldsList = computed(() => store.getters['board/getVisibleFields']);

    const ticketId = tData.id;
    const onToggleExpand = async () => {
      store.commit("board/setExpandTicketById", { id: ticketId, isExpanded: !isExpanded.value });
      try {
        await TicketService.toggleTicketExpanded(ticketId, !isExpanded.value)
      } catch (e) {
        console.log('TicketCard toggleTicketExpanded error', e)
      }
    }

    const onToggleStar = function () {
      const s = (parseInt(ticket.value.star!, 10) + 1) % 4;
      store.commit("board/setStarTicketById", { id: ticketId, star: s })
      try {
        TicketService.setTicketStar(ticketId, s.toString())
      } catch (e) {
        console.log('TicketCard onToggleStar setTicketStar error', e)
      }

    }

    const onClickRemoveCover = async () => {
      console.log('onClickRemoveCover', ticket.value.id);
      //store.dispatch("board/removeCover", { ticketId: ticket.value.id })

      const ticketId = ticket.value.id
      const blockType = 'TicketCard_removeCover'
      const blockAttrs = {ticketId}

      if (autorefreshBlockExists(blockType, blockAttrs)) {
        return false
      }

      autorefreshBlockAdd(blockType, blockAttrs)

      try {
        const cancelTokenId = `setCover_${ticketId}`
        ApiService.cancelRequest(cancelTokenId)
        const result = await TicketService.setCover(ticketId, '-1', cancelTokenId)
        showResponseSuccessMessage(result)
        if (isSuccessServerResponse(result)) {
          store.commit("board/setTicketCoverById", {id: ticketId, cover: null});
        }
      } catch (e) {
        console.log('TicketCard setCover error', e)
      }
      autorefreshBlockRemove(blockType, blockAttrs)

    }

    onUpdated(() => {
      //console.log('tId updated', tData.id);
    });

    const owner = tData.owner;

    onBeforeUnmount(() => {
      tData = null;
    })

    const isShowAvatars = computed(() => options.value.bw_user_field == BW_OPT_1.BW_OPT_SHOW_USERS_AVATARS);
    const maxAvatars = 4;
    const maxLengthNames = 60;

    const sUsers = computed(() => {
      const _users: any[] = props.users;
      if (_users && _users.length > 0) {
        if (isShowAvatars.value) {
          return _users.slice(0, maxAvatars);
        } else {
          let maxLength = maxLengthNames;
          let i = 0;
          while (maxLength > 0 && i < _users.length) {
            maxLength = maxLength - _users[i].name.length - 2;
            i++;
          }
          return _users.slice(0, maxLength < 0 ? i -1 : i);
        }
      }
      return [];
    });

    const hiddenUsersCount = computed(() => {
      const _users = props.users!;
      return _users.length - sUsers.value.length;
    });

    const targetMenu = ref();
    const target = ref();

    const ticketMenuHandler = function (event) {
      // console.log('TicketCard ticketMenuHandler', Object.is(ticket.value, tData), ticket.value, tData)
      console.log('TicketCard ticketMenuHandler', ticket.value.color, ticket.value.tmpColor);
      emit('ticketMenu', { event, item: ticket.value })
    }

    return {
      t,
      tData,
      title,
      isDark,
      isReadonly,
      isShowCover,
      isShowDetailsArrow,
      isShowTags,
      isNew,
      isUpdate,
      isShowOwner,
      isShowUsers,
      isShowDeadline,
      isShowCreateDate,
      isShowTodos,
      isShowNotes,
      isLastNoteActive,
      noteCnt,
      customFieldsList,
      isShowAvatars,
      lastNote,
      isNoteActive,
      onToggleExpand,
      onToggleStar,
      owner,
      sUsers,
      hiddenUsersCount,
      targetMenu,
      target,
      openFancybox,
      onClickRemoveCover,
      ticketMenuHandler,
    };
  },

});
