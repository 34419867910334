
import {computed, defineComponent, nextTick, onBeforeUnmount, onMounted, ref, reactive, toRef} from 'vue'
import TheBoard from "@/components/TheBoard";
import store from "@/store";
import {useScreenDocumentTitle} from "@/composables/useScreenDocumentTitle";
import {useFetchState} from "@/composables/useFetchState";
//import AppLoader from "@/components/AppLoader.vue";
import TicketsToolbar from "@/components/TicketsToolbar"
//import { toRefs } from '@vueuse/shared';
import {useCurrentScreen} from "@/composables/useCurrentScreen"
import {onBeforeRouteLeave} from "vue-router"
import {useAppUtils} from "@/composables/useAppUtils"
import MenuScreenBoard from "@/components/Menus/MenuScreenBoard.vue"
import {useFloatingExists} from "@/composables/useFloatingExists"
import {useHighlight} from '@/composables/useHighlight'

export default defineComponent({
  name: 'BoardViewScreen',
  props: {
    projectId: String,
    search: String,
    searchIn: String,
    searchFields: String,
    requestedById: String,
    assignedToId: String,
    serverParam: {
      type: String,
      required: false
    },
  },
  components: {
    //AppLoader,
    TheBoard,
    TicketsToolbar,
    MenuScreenBoard,
  },

  setup(props) {
    console.log('BoardView setup');
    const isBoardShow = ref(false);
    //const projectId = toRefs(props);
    const project = computed(() => store.getters['project']);
    const canAddTicket = computed(() => store.getters['board/getRole']?.canAddTicket)
    //const isLoading = computed(()=>store.getters['board/isLoading']);

    const {
      isMayContinue,
      showFullPanelsLoader,
      showPanelsLoaders,
      hidePanelsLoaders,
      hideStartLoader,
      getAuthUser,
    } = useAppUtils()

    const authUser = getAuthUser()
    const authUserId = authUser?.id.toString()

    const screenMenuValueShow = computed(() => {
      //requestedById: '0', assignedToId: '0'
      const requestedById = store.getters['ticketsfilters/requestedById'] || '0'
      const assignedToId = store.getters['ticketsfilters/assignedToId'] || '0'
      let value = ''
      if (requestedById === '0' && assignedToId === '0') {
        value = 'all'
      } else if (requestedById === '0' && assignedToId === authUserId) {
        value = 'assigned_to_me'
      } else if (requestedById === authUserId && assignedToId === '0') {
        value = 'requested_by_me'
      }
      return value
    })
    const screenMenuValueRearrange = ref('created')


    const moduleName = 'board';
    useCurrentScreen(moduleName)
    useScreenDocumentTitle(moduleName);

    onBeforeRouteLeave(async () => {
      if (!await isMayContinue()) { return false }
    })


    const {
      fetchStateInit,
      changeStateParams,
      getRouteObj,
      initAutorefresh,
      cancelAutorefresh,
      fetchState,
    } = useFetchState({
      storeNamespace: moduleName,
    })

    const setLoading = function (val) {
      //store.commit('board/setLoading', val);
      val ? showFullPanelsLoader() : hidePanelsLoaders()
      isBoardShow.value = !val;
    }

    const storeProjectId = store.state[moduleName]?.projectId

    let isLoaderHidden = false

    if (store.getters['board/isLoaded'] && storeProjectId && props.projectId && storeProjectId.toString() === props.projectId.toString()) {
      isLoaderHidden = true
      hidePanelsLoaders()
    }

    const refScreenMenu = ref<InstanceType<typeof MenuScreenBoard> | null>(null)
    const refScreenMenuTarget = ref()
    const {isExist: isExistScreenMenu, triggerHandler: triggerHandlerScreenMenu} = useFloatingExists(refScreenMenu)

    const {
      getTicketIdToHighlight,
      setTicketIdToHighlight,
      highlightTicketOnBoard
    } = useHighlight()

    const ticketId = getTicketIdToHighlight()

    let timer1;
    onMounted(async () => {
      // await nextTick();
      // if (!store.getters['board/isLoaded']) {
      //   setLoading(true);
      // }
      //setLoading(!store.getters['board/isLoaded']);
      await nextTick();
      if (isLoaderHidden && ticketId) {
        highlightTicketOnBoard(ticketId)
      }
      try {
        await fetchStateInit(props).finally(async ()=>{
          await nextTick();
          setLoading(false);
        });
        initAutorefresh()
        await hidePanelsLoaders()
        hideStartLoader()
        if (!isLoaderHidden && ticketId) {
          highlightTicketOnBoard(ticketId)
        }
        setTicketIdToHighlight(null)
      } catch (e) {
        console.log('BoardViewScreen onMounted error', e, props)
      }
    })

    onBeforeUnmount(() => {
      clearTimeout(timer1);
      cancelAutorefresh();
    });

    const toolbarTriggerMenu = async (params) => {
      console.log('Board toolbarTriggerMenu', params)
      refScreenMenuTarget.value = params.positionTarget.value
      await triggerHandlerScreenMenu()
    }

    const screenMenuActionHandler = async (action, data: any = {}) => {
      console.log('Board screenMenuActionHandler', action, data)
      if (refScreenMenu.value) {
        refScreenMenu.value.isOpened = false
      }

      let stateParams
      switch (action) {
        case 'show_all':
          stateParams = {requestedById: '0', assignedToId: '0'}
          break
        case 'show_assigned_to_me':
          stateParams = {requestedById: '0', assignedToId: authUserId}
          break
        case 'show_requested_by_me':
          stateParams = {requestedById: authUserId, assignedToId: '0'}
          break
        case 'rearrange_required_by':
          stateParams = {resort_by: 'required_by'}
          screenMenuValueRearrange.value = 'required_by'
          break
        case 'rearrange_name':
          stateParams = {resort_by: 'name'}
          screenMenuValueRearrange.value = 'name'
          break
        case 'rearrange_created':
          stateParams = {resort_by: 'created'}
          screenMenuValueRearrange.value = 'created'
          break
      }
      if (stateParams) {
        showPanelsLoaders()
        if (action.startsWith('show_')) {
          await changeStateParams(stateParams)
        } else {
          await fetchState(stateParams)
        }
        await hidePanelsLoaders()
      }
    }


    return {
      isBoardShow,
      //isLoading,
      project,
      canAddTicket,
      refScreenMenuTarget,
      refScreenMenu,
      isExistScreenMenu,
      screenMenuValueShow,
      screenMenuValueRearrange,
      toolbarTriggerMenu,
      screenMenuActionHandler,
      // onLoad
    }
  },
});

